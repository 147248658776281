/*==============
    RESPONSIVE
=============*/


@media(max-width:992px){
    .center-md{
        margin: inherit;
    }

}


@media(max-width:768px){
 .center-md{
     margin: 0 auto;
 }


}


@media(max-width:576px){

    .center-md{
        margin: 0 auto;
    }

    .slide-mobile-padding{
        padding: 0 1em;
    }

    header nav.navbar{
        padding:0.5em 0 0.5em 2.5em;
    }
    
    #contact{
        .contact-group{
            
            flex-direction:column;
            
            a{
                border-radius:0;
                max-width:300px;
                margin:0 auto;
            }
        }
    }



}
