/*==============
    GENERAL
=============*/

body{
    color:$one;
    font-family: 'Raleway', sans-serif;
}

html, body{
    min-height:100vh;
}


/*==============
    CLASSES
=============*/

.bg-modal{
    background: rgba(0,0,0,0.7);
    height:100%;
    width:100%;
    position:fixed;
    z-index:6;

}

.break{
    width:100%;
}

.overflow-x-hide{
    overflow-x:hidden;
}



.overflow-hide{
    overflow:hidden;
}


