/*==============
    ANIMATION
=============*/






.social-section{
    min-width:90px;
    width:100%;
    max-width:400px;
}






.slide-title{
     animation-delay: 0.3s;
     animation-duration:0.25s;
}

.slide-date,
.slide-summary,
.slide-facts{
     animation-delay: 0.6s;
     animation-duration:0.25s;
}

.btn-container,
.big,
.medium,
.small{
    animation-delay: 0.8s;

}



.smallHover{
  animation: hovering infinite ease 9s;
  animation-delay:1.5s;
  animation-fill-mode:forwards;

}

@keyframes hovering{

  from{ }
  50%{ transform:translateY(-25px); }
  to{}

}


.imgList{
    padding:0;
    margin:0;
}

.contact-links a{
    font-size:28px;
    transition:all 0.3s;
}


.flex{
    display:flex;
}


#about-title,
#frontend{
    animation-delay:0.3s;
}

#bio,
#backend{
    animation-delay:0.8s;

}

#experience{
    animation-delay:1.2s;
}





div.web-skills{
    margin:2em 0;
}

.slide:nth-of-type(even) .big{
    /*background: #192b66;*/
    background: #09174C;
}

.slide:nth-of-type(odd) .big{
    background: #192b66;
}



#wrapper{
    background:#3E4F89;
}

.tiny{
    font-size:17px;
    margin-right:-8px;
}


button.direction{
    z-index:4;
}


#pagination-container{
    min-height:20px;
}


@media all and (max-width:578px){

    .btn-container a.left{
        border-radius:25px;

    }

    .btn-container{
        padding:15px 0;
    }

    .btn-container a.right{
        border-radius:25px;
    }

}
