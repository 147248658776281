$red: #ff4d4d;
$green: #33cc33;
$blue: #3399ff;
$l-grey: #ccc;
$purp: purple;


$white: white;
$blue-1:#97A4CB ;
$blue-2:#5169A9 ;
$blue-3:#3E4F89;
$grey-1:#eee;
$grey-2: #606060;

$one: white;
$two:#97A4CB ;
$three: #5169A9 ;
$four:  #3E4F89;
$five: #eee;
$six: #606060;
